import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import navigationItemReferences from "../../../../constants/navigation-item-references";
import pages from "../../../../constants/pages";
import { getDataGridFakeData } from "../../../../helpers/getDataGridFakeData";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../../hooks/useDatagridSorting";
import useToastAlert from "../../../../hooks/useToastAlert";
import { selectOrganizationIdentityProvider, selectUser } from "../../../../redux/userSlice";
import CoAppDataGridDateTimeCell from "../../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridNameCell from "../../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppDataGridSkeletonCell from "../../../global/components/datagrid/coapp-datagrid-skeleton-cell";
import CoAppStandardDataGrid from "../../../global/components/datagrid/coapp-standard-datagrid";
import CoAppAddItemsToListModal from "../../../global/components/modals/coapp-add-items-to-list-modal";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import CoAppEditNameModal from "../../../global/components/modals/coapp-edit-name-modal";
import { CoAppDataGridActionsContainer } from "../../../global/styled/global.styled";

const roleIdpGroupColumns = ["actions", "name", "createdAt"];

export default function RoleSSO() {
	const dummyRows = getDataGridFakeData(roleIdpGroupColumns);
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("roleIdpGroups");
	const { updateCoAppNavigation } = useCoAppNavigation();
	const currentUser = useSelector(selectUser);
	const identityProviderName = useSelector(selectOrganizationIdentityProvider);
	const navigate = useNavigate();
	const { roleId } = useParams();
	const { pathname, state } = useLocation();

	const [addIdPGroupsIsToggled, setAddIdPGroupsIsToggled] = useState(false);
	const [idpGroups, setIdpGroups] = useState(dummyRows);
	const [availableIdpGroups, setAvailableIdpGroups] = useState([]);
	const [roleIdpGroups, setRoleIdpGroups] = useState([]);
	const [idpGroupsToAdd, setIdpGroupsToAdd] = useState([]);
	const [selectedIdpGroups, setSelectedIdpGroups] = useState([]);
	const [removeIdpGroupsIsToggled, setRemoveIdpGroupsIsToggled] = useState(false);
	const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = useState(false);
	const [editRoleDialogOpen, setEditRoleDialogOpen] = useState(false);
	const [roleNameInput, setRoleNameInput] = useState("");
	const [roleNameError, setRoleNameError] = useState("");
	const [loading, setLoading] = useState(true);

	const columns = [
		{
			field: "name",
			headerName: identityProviderName ? `${identityProviderName} Group` : "Group",
			disableExport: true,
			sortable: true,
			editable: true,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={params.row} />}
				/>
			),
		},
		{
			field: "createdAt",
			headerName: "Date Mapped",
			disableExport: true,
			sortable: true,
			editable: true,
			flex: 1,
			valueGetter: (params) => params.value === "" ? "" : new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} />}
				/>
			),
		},
		{
			field: "actions",
			disableExport: true,
			sortable: false,
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridActionsContainer>
							<Delete
								onClick={() => {
									removeIdPGroupFromRole(params.row.id);
								}}
							/>
						</CoAppDataGridActionsContainer>
					}
				/>
			),
		},
	];


	const toggleDeleteRoleDialog = () => {
		setDeleteRoleDialogOpen(!deleteRoleDialogOpen);
	};

	const deleteRole = () => {
		axios.delete(apiRoutes.deleteRole + "/" + roleId, {
			headers: authHeader
		}).then(() => {
			updateCoAppNavigation(true, navigationItemReferences.roleNavItemIndex);
			handleToastAlert(messageLevels.INFO, messages.ROLE_DELETION_SUCCESS_MSG);
			navigate(pages.roleManagement, { replace: true });
			toggleDeleteRoleDialog();
		}).catch((err) => {
			console.log(err);
			handleToastAlert(messageLevels.ERROR, messages.ROLE_DELETION_ERROR_MSG);
			toggleDeleteRoleDialog();
		});
	};

	const toggleEditRoleDialog = () => {
		setEditRoleDialogOpen(!editRoleDialogOpen);
		setRoleNameInput("");
		setRoleNameError("");
	};

	const handleRoleNameChange = (e) => {
		setRoleNameInput(e.target.value);
		setRoleNameError("");
	};

	const handleRoleNameChangeSubmit = (e) => {
		if (!roleNameInput) return;

		if (roleNameInput === state.name) {
			toggleEditRoleDialog();
			return;
		}

		axios.put(apiRoutes.updateRole(roleId), { name: roleNameInput }, { headers: authHeader })
			.then(() => {
				toggleEditRoleDialog();
				updateCoAppNavigation(false, navigationItemReferences.roleNavItemIndex, roleNameInput, pathname);
			})
			.catch((e) => {
				console.log(e);
				if (e.response.data.message.includes("already exists")) {
					setRoleNameError(messages.RESOURCE_NAME_ALREADY_EXISTS_ERROR_MSG("Role"));
					return;
				}
				toggleEditRoleDialog();
				handleToastAlert(messageLevels.ERROR, messages.ROLE_NAME_UPDATE_ERROR_MSG);
			});
	};

	const addIdPGroupsToRole = () => {
		axios.put(apiRoutes.updateRoleIdpGroups(roleId), { idpgroups: idpGroupsToAdd }, { headers: authHeader })
			.then(() => {
				init();
				toggleAddNewDialog();
				handleToastAlert(messageLevels.SUCCESS, messages.IDP_GROUPS_ADDITION_SUCCESS_MSG(identityProviderName));
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.IDP_GROUPS_ADDITION_ERROR_MSG(identityProviderName));
			});
	};

	const addIdPGroupsToSelectedIdpGroups = (value) => {
		//value is an array of idpGroup names, we need to convert them to idpGroup objects to display the group name properly
		value = value.map((idpGroup) => {
			let tempArr = [...roleIdpGroups, ...availableIdpGroups];
			let foundIdpGroup = tempArr.find((row) => row.name === idpGroup);
			return foundIdpGroup;
		});
		setIdpGroupsToAdd(value);
	};

	const init = () => {
		axios.get(apiRoutes.getRoleIdpGroups(roleId), { headers: authHeader })
			.then((response) => {
				setRoleIdpGroups(response.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const initAvailableIdpGroups = () => {
		let available = [];
		if (idpGroups && idpGroups.length > 0) {
			idpGroups.forEach((idpGroup) => {
				if (!roleIdpGroups.some((roleIdpGroup) => roleIdpGroup.name === idpGroup.externalGroupName)) {
					available.push({ externalGroupId: idpGroup.externalGroupId, name: idpGroup.externalGroupName });
				}
			});
		}
		setAvailableIdpGroups(available);
	};

	const initIdpGroups = () => {
		axios.get(apiRoutes.getExternalGroups(currentUser.organizationId), { headers: authHeader })
			.then((response) => {
				setIdpGroups(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const removeIdPGroupFromRole = (idpGroupId) => {
		axios.delete(apiRoutes.removeIdpGroupFromRole(roleId, idpGroupId), { headers: authHeader })
			.then(() => {
				init();
				handleToastAlert(messageLevels.INFO, messages.IDP_GROUP_REMOVAL_SUCCESS_MSG(identityProviderName, false));
			})
			.catch((error) => {
				console.log(error);
				handleToastAlert(messageLevels.ERROR, messages.IDP_GROUP_REMOVAL_ERROR_MSG(identityProviderName));
			});
	};

	const bulkRemoveIdpGroupsFromRole = () => {
		const deleteData = {
			idpgroupIds: selectedIdpGroups
		};
		axios.delete(apiRoutes.bulkRemoveIdpGroupsFromRole(roleId), { headers: authHeader, data: deleteData })
			.then(() => {
				init();
				setRemoveIdpGroupsIsToggled(false);
				handleToastAlert(messageLevels.INFO, messages.IDP_GROUP_REMOVAL_SUCCESS_MSG(identityProviderName, selectedIdpGroups.length > 1));
			})
			.catch(err => {
				console.log(err);
				handleToastAlert(messageLevels.ERROR, messages.IDP_GROUP_REMOVAL_ERROR_MSG(identityProviderName));
			});
	};

	const toggleAddNewDialog = () => {
		setAddIdPGroupsIsToggled(!addIdPGroupsIsToggled);

		if (!addIdPGroupsIsToggled) {
			initAvailableIdpGroups();
			setAddIdPGroupsIsToggled(true);
		} else {
			setAddIdPGroupsIsToggled(false);
			setAvailableIdpGroups([]);
			setIdpGroupsToAdd([]);
		}
	};

	useEffect(() => {
		init();
		initIdpGroups();
		handleInitializeSortModel();
	}, [state.name]);

	return (
		<>
			<CoAppDatagridHeader
				title={`${state.name} ${identityProviderName ? identityProviderName + " " : "IdP "}Group Management`}
				subResourceType={identityProviderName ? `${identityProviderName} Group` : "IdP Group"}
				addOnClickHandler={toggleAddNewDialog}
				resourceType="Role"
				addIsPresent={true}
				editIsPresent={state.isSuperAdmin ? false : true}
				editOnClickHandler={toggleEditRoleDialog}
				deleteIsPresent={state.isSuperAdmin ? false : true}
				deleteOnClickHandler={toggleDeleteRoleDialog}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={roleIdpGroups}
				allowSelection={true}
				pinnedColumns={["actions", "__check__"]}
				targetResource={identityProviderName ? `${identityProviderName} groups` : "groups"}
				parentResource="role"
				parentResourceName={state.name}
				bulkActionText="Remove"
				selectionModel={selectedIdpGroups}
				setSelectionModel={setSelectedIdpGroups}
				handleModalToggle={() => setRemoveIdpGroupsIsToggled(prev => !prev)}
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
			<CoAppAddItemsToListModal
				addButtonTitle="Save"
				inputLabel={`${identityProviderName} Group(s)`}
				addItemsIsToggled={addIdPGroupsIsToggled}
				addItemsToCollection={addIdPGroupsToRole}
				addItemsToNewArray={addIdPGroupsToSelectedIdpGroups}
				dialogSearchPlaceholder={`Search ${identityProviderName ? identityProviderName + " " : ""}Groups`}
				dialogTitle={`Add ${identityProviderName ? identityProviderName + " " : ""}Group(s) to ${state.name} role`}
				items={availableIdpGroups}
				itemsToAdd={idpGroupsToAdd}
				toggleDialog={toggleAddNewDialog}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={deleteRoleDialogOpen}
				dialogTitle={`Delete ${state.name} role?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(state.name, "role")}
				confirmClickHandler={deleteRole}
				cancelClickHandler={toggleDeleteRoleDialog}
				actionText="Delete"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={removeIdpGroupsIsToggled}
				dialogTitle={`Remove selected (${selectedIdpGroups.length}) ${identityProviderName} ${selectedIdpGroups.length > 1 ? "groups" : "group"} from ${state.name}?`}
				dialogMessage={messages.IDP_GROUP_REMOVAL_MODAL_MSG(identityProviderName, state.name, "role")}
				confirmClickHandler={bulkRemoveIdpGroupsFromRole}
				cancelClickHandler={() => setRemoveIdpGroupsIsToggled(false)}
				actionText="Remove"
			/>
			<CoAppEditNameModal
				dialogOpen={editRoleDialogOpen}
				dialogTitle="Edit role name"
				changeHandler={handleRoleNameChange}
				placeholderText={state.name}
				confirmClickHandler={handleRoleNameChangeSubmit}
				cancelClickHandler={toggleEditRoleDialog}
				actionText="Save"
				editNameError={roleNameError}
				setEditNameError={setRoleNameError}
			/>
		</>
	);
}