import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Switch } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import { getDataGridFakeData } from "../../../helpers/getDataGridFakeData";
import useAuthHeader from "../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../hooks/useCoAppNavigation";
import useDatagridSorting from "../../../hooks/useDatagridSorting";
import useToastAlert from "../../../hooks/useToastAlert";
import { selectOrganizationSsoAndScim } from "../../../redux/userSlice";
import CoAppDataGridDateTimeCell from "../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridListCell from "../../global/components/datagrid/coapp-datagrid-list-cell";
import CoAppDataGridNameCell from "../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppDataGridSkeletonCell from "../../global/components/datagrid/coapp-datagrid-skeleton-cell";
import CoAppStandardDataGrid from "../../global/components/datagrid/coapp-standard-datagrid";
import CoAppCreateUserModal from "../../global/components/modals/coapp-create-user-modal";
import CoAppDestructiveConfirmationModal from "../../global/components/modals/coapp-destructive-confirmation-modal";
import ThreeDotMenu from "../../global/components/three-dot-menu";

const userColumns = ["actions", "user", "status", "roles", "groups", "lastExtensionHeartbeat", "createdAt"];

const userCreateInitialState = {
	email: "",
	firstName: "",
	lastName: "",
	roles: [],
	emailError: "",
	firstNameError: "",
	lastNameError: "",
	roleError: "",
};

const customNameComparator = (v1, v2) => {
	if (!v1 && !v2) return;
	return v1.localeCompare(v2);
};

export default function UserManagement() {
	const dummyRows = getDataGridFakeData(userColumns);
	const authHeader = useAuthHeader();
	const { viewUserPageFromUserManagement } = useCoAppNavigation();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("users");
	const { handleToastAlert } = useToastAlert();

	const organizationSsoStatus = useSelector(selectOrganizationSsoAndScim);
	const [organizationUsers, setOrganizationUsers] = useState(dummyRows);
	const [userBeingDeleted, setUserBeingDeleted] = useState({});
	const [userCreationDialogOpen, setUserCreationDialogOpen] = useState(false);
	const [userCreateData, setUserCreateData] = useState(userCreateInitialState);
	const [availableRoles, setAvailableRoles] = useState([]);
	const [userDeletionDialogOpen, setUserDeletionDialogOpen] = useState(false);
	const [userSeed, setUserSeed] = useState(1);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [bulkDeleteDialogOpen, setBulkDeleteDialogOpen] = useState(false);
	const [loading, setLoading] = useState(true);

	const columns = [
		{
			field: "user",
			headerName: "Name",
			sortable: true,
			valueGetter: (params) => params.value === "" ? "" : params.row.email,
			sortComparator: customNameComparator,
			editable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridNameCell item={params.row} isUser={true} isCoAppSuperAdmin={params.row.isCoAppSuperAdmin} />
					}
				/>
			),
		},
		{
			field: "roles",
			headerName: "Roles",
			sortable: false,
			editable: true,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridListCell items={params.value} resource="Roles" isCoAppSuperAdmin={params.row.isCoAppSuperAdmin} />
					}
				/>
			),
		},
		{
			field: "groups",
			headerName: "Groups",
			editable: false,
			sortable: false,
			flex: 1,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridListCell items={params.value} resource="Groups" isCoAppSuperAdmin={params.row.isCoAppSuperAdmin} />}
				/>
			),
		},
		{
			field: "lastExtensionHeartbeat",
			headerName: "Last extension heartbeat",
			editable: false,
			sortable: true,
			flex: 1,
			valueGetter: (params) => params.value === "" ? "" : new Date(params.row.workstation?.lastPhoneHome),
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<CoAppDataGridDateTimeCell value={params.row.workstation?.lastPhoneHome} isCoAppSuperAdmin={params.row.isCoAppSuperAdmin} />
					}
				/>
			),
		},
		{
			field: "createdAt",
			headerName: "Created",
			sortable: true,
			editable: false,
			flex: 1,
			valueGetter: (params) => params.value === "" ? "" : new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} isCoAppSuperAdmin={params.row.isCoAppSuperAdmin} />}
				/>
			),
		},
		{
			field: "active",
			headerName: "Status",
			sortable: true,
			editable: false,
			flex: 0.5,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<Switch
							checked={params.row.active}
							disabled={organizationSsoStatus || params.row.isCoAppSuperAdmin}
							onChange={() => handleUserStatusToggle(params.row.id, params.row.active === true ? "false" : "true")}
						/>
					}
				/>
			),
		},
		{
			field: "actions",
			type: "actions",
			flex: .1,
			resizable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<div>
							{
								organizationSsoStatus ?
									<ThreeDotMenu
										options={[
											{
												name: "View User",
												optionClickHandler: () => {
													viewUserPageFromUserManagement(params);
												},
											},
										]}
										isCoAppSuperAdmin={params.row.isCoAppSuperAdmin}
									/>
									:
									<ThreeDotMenu
										options={
											[
												{
													name: "Edit",
													optionClickHandler: () => {
														viewUserPageFromUserManagement(params);
													},
												},
												{
													name: "Delete",
													optionClickHandler: () => {
														handleOpenUserDeletionDialog({ params });
													},
												},
											]
										}
										isCoAppSuperAdmin={params.row.isCoAppSuperAdmin}
									/>
							}
						</div>
					}
				/>
			)
		}
	];

	const handleCloseUserDeletionDialog = () => {
		setUserDeletionDialogOpen(false);
		setUserBeingDeleted({});
	};

	const handleOpenUserDeletionDialog = (rowObject) => {
		let userBeingDeleted = rowObject.params.row;
		setUserBeingDeleted(userBeingDeleted);
		setUserDeletionDialogOpen(true);
	};

	const handleUserStatusToggle = (id, state) => {
		let userJSON = {
			active: state
		};
		axios.put(apiRoutes.setUserActive + "/" + id, userJSON, { headers: authHeader })
			.then(() => {
				initUserManagement();
			})
			.catch((error) => {
				console.error(error);
				handleToastAlert(messageLevels.ERROR, messages.USER_TOGGLE_ERROR_MSG);
			});
	};

	const handleUserCreateDataChange = (key, value) => {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		switch (key) {
			case "email":
				if (!emailRegex.test(value)) {
					setUserCreateData({ ...userCreateData, email: value, emailError: value.length === 0 ? "" : "Invalid email" });
					return;
				} else {
					setUserCreateData({ ...userCreateData, email: value, emailError: "" });
				}
				break;
			case "firstName":
				setUserCreateData({ ...userCreateData, firstName: value, firstNameError: "" });
				break;
			case "lastName":
				setUserCreateData({ ...userCreateData, lastName: value, lastNameError: "" });
				break;
			case "roles":
				value = value.map((role) => {
					let foundRole = availableRoles.find((availableRole) => availableRole.name === role);
					return foundRole;
				});
				setUserCreateData({ ...userCreateData, roles: value, roleError: "" });
				break;
			default:
				break;
		}
	};

	const validateUserCreateData = () => {
		let isValid = true;
		let emailError = "";
		let firstNameError = "";
		let lastNameError = "";
		let roleError = "";

		if (!userCreateData.email) {
			emailError = "Required *";
			isValid = false;
		}

		if (!userCreateData.firstName) {
			firstNameError = "Required *";
			isValid = false;
		}

		if (!userCreateData.lastName) {
			lastNameError = "Required *";
			isValid = false;
		}

		if (userCreateData.roles.length === 0) {
			roleError = "Required *";
			isValid = false;
		}

		setUserCreateData({
			...userCreateData,
			emailError: emailError,
			firstNameError: firstNameError,
			lastNameError: lastNameError,
			roleError: roleError,
		});

		return isValid;
	};

	const createUser = () => {
		if (validateUserCreateData()) {
			let userCreationObject = {
				email: userCreateData.email,
				firstName: userCreateData.firstName,
				lastName: userCreateData.lastName,
				roles: userCreateData.roles.map((role) => role.name)
			};
			axios.post(apiRoutes.createUser, userCreationObject, {
				headers: authHeader,
			}).then(() => {
				handleCloseUserCreateModal();
				setUserSeed(Math.random());
				handleToastAlert(messageLevels.SUCCESS, messages.USER_CREATION_SUCCESS_MSG);
			}).catch((err) => {
				console.error(err);
				if (err.response.data.message.includes("already exists")) {
					setUserCreateData({ ...userCreateData, emailError: messages.EMAIL_ALREADY_EXISTS_ERROR_MSG });
					return;
				}
				handleToastAlert(messageLevels.ERROR, err.response.data.message || messages.USER_CREATION_ERROR_MSG);
			});
		}
	};

	const deleteUser = () => {
		axios.delete(apiRoutes.deleteUser + "/" + userBeingDeleted.id, {
			headers: authHeader
		}).then(() => {
			handleCloseUserDeletionDialog();
			setUserSeed(Math.random());
			handleToastAlert(messageLevels.INFO, messages.USER_DELETION_SUCCESS_MSG(false));
		}).catch(err => {
			console.error(err);
			handleToastAlert(messageLevels.ERROR, messages.USER_DELETION_ERROR_MSG);
		});
	};

	const bulkDeleteUsers = () => {
		const userObjs = organizationUsers.filter((user) => selectedUsers.includes(user.id)).map((user) => { return { id: user.id, descopeUserId: user.descopeUserId }; });
		const deleteData = {
			usersToDelete: userObjs
		};
		axios.delete(apiRoutes.bulkDeleteUsers, { headers: authHeader, data: deleteData })
			.then(() => {
				setBulkDeleteDialogOpen(false);
				setSelectedUsers([]);
				setUserSeed(Math.random());
				handleToastAlert(messageLevels.INFO, messages.USER_DELETION_SUCCESS_MSG(selectedUsers.length > 1));
			})
			.catch((err) => {
				console.error(err);
				setBulkDeleteDialogOpen(false);
				handleToastAlert(messageLevels.ERROR, messages.USER_DELETION_ERROR_MSG);
			});
	};

	const initUserManagement = () => {
		axios.get(apiRoutes.getUsers, { headers: authHeader, })
			.then((res) => {
				setOrganizationUsers(res.data);
				setLoading(false);
			}).catch((err) => {
				console.log(err);
			});
	};

	const handleCloseUserCreateModal = () => {
		setUserCreationDialogOpen(false);
		setAvailableRoles([]);
		setUserCreateData(userCreateInitialState);
	};

	const toggleAddNewDialog = () => {
		axios.get(apiRoutes.getRoles, { headers: authHeader })
			.then(response => {
				setAvailableRoles(response.data);
			})
			.catch(error => {
				console.error("Error fetching roles:", error);
			});
		setUserCreationDialogOpen(!userCreationDialogOpen);
	};

	useEffect(() => {
		initUserManagement();
		handleInitializeSortModel();
	}, [userSeed]);

	return (
		<>
			<CoAppDatagridHeader
				title="Users"
				resourceType="User"
				addOnClickHandler={toggleAddNewDialog}
				addIsPresent={organizationSsoStatus ? false : true}
				editIsPresent={false}
				deleteIsPresent={false}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={organizationUsers}
				pinnedColumns={
					organizationSsoStatus ?
						["actions", "name", "active"]
						:
						["actions", "__check__", "name", "active"]
				}
				allowSelection={organizationSsoStatus ? false : true}
				targetResource="users"
				selectionModel={selectedUsers}
				setSelectionModel={setSelectedUsers}
				handleModalToggle={() => setBulkDeleteDialogOpen(true)}
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={userDeletionDialogOpen}
				dialogTitle={`Delete ${userBeingDeleted.email}?`}
				dialogMessage={messages.DELETION_CONFIRMATION_MSG(userBeingDeleted.email, "user")}
				confirmClickHandler={deleteUser}
				cancelClickHandler={handleCloseUserDeletionDialog}
				actionText="Delete"
			/>
			<CoAppDestructiveConfirmationModal
				dialogOpen={bulkDeleteDialogOpen}
				dialogTitle={`Delete selected ${selectedUsers.length > 1 ? "users" : "user"}?`}
				dialogMessage={"Are you sure you want to bulk delete all the selected users? This action cannot be undone."}
				confirmClickHandler={bulkDeleteUsers}
				cancelClickHandler={() => setBulkDeleteDialogOpen(false)}
				actionText="Delete"
			/>
			<CoAppCreateUserModal
				dialogOpen={userCreationDialogOpen}
				availableRoles={availableRoles}
				cancelClickHandler={handleCloseUserCreateModal}
				confirmClickhandler={createUser}
				userDataChangeHandler={handleUserCreateDataChange}
				userData={userCreateData}
			/>
		</>
	);
}