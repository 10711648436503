import { useEffect, useState } from "react";
import axios from "axios";

import apiRoutes from "../../../constants/api-routes";
import messageLevels from "../../../constants/message-levels";
import messages from "../../../constants/messages";
import { getDataGridFakeData } from "../../../helpers/getDataGridFakeData";
import useAuthHeader from "../../../hooks/useAuthHeader";
import useDatagridSorting from "../../../hooks/useDatagridSorting";
import useToastAlert from "../../../hooks/useToastAlert";
import CoAppDataGridDateTimeCell from "../../global/components/datagrid/coapp-datagrid-datetime-cell";
import CoAppDatagridHeader from "../../global/components/datagrid/coapp-datagrid-header";
import CoAppDataGridNameCell from "../../global/components/datagrid/coapp-datagrid-name-cell";
import CoAppDataGridSkeletonCell from "../../global/components/datagrid/coapp-datagrid-skeleton-cell";
import CoAppStandardDataGrid from "../../global/components/datagrid/coapp-standard-datagrid";
import CoAppKillswitchModal from "../../global/components/modals/coapp-killswitch-modal";

import { WorkstationSwitch } from "./styled/workstation.styled";
import WorkstationDataGridSelectionRow from "./workstation-datagrid-selection-row";

const workstationColumns = ["serialNumber", "killSwitchActive", "hostName", "ip", "macAddress", "lastLogUpload", "lastPhoneHome"];

export default function WorkstationManagement() {
	const dummyRows = getDataGridFakeData(workstationColumns);
	const authHeader = useAuthHeader();
	const { handleToastAlert } = useToastAlert();
	const { sortModel, handleSortModelChange, handleInitializeSortModel } = useDatagridSorting("workstations");
	const [workstationsSelected, setWorkstationsSelected] = useState([]);
	const [workstationKillswitchDialogOpen, setWorkstationKillswitchDialogOpen] = useState(false);
	const [workstations, setWorkstations] = useState(dummyRows);
	const [workstationsForDisplayInModal, setWorkstationsForDisplayInModal] = useState([]);
	const [activateIsDisabled, setActivateIsDisabled] = useState(false);
	const [deactivateIsDisabled, setDeactivateIsDisabled] = useState(false);
	const [actionType, setActionType] = useState("Activate");
	const [loading, setLoading] = useState(true);

	const columns = [
		{
			field: "serialNumber",
			headerName: "Serial Number",
			sortable: true,
			flex: 1,
			editable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={{ name: params.value }} />}
				/>
			)
		},
		{
			field: "killSwitchActive",
			headerName: "Kill Switch Status",
			sortable: true,
			flex: 1,
			editable: true,
			type: "boolean",
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={
						<WorkstationSwitch
							checked={params.row.killSwitchActive}
							disabled={false}
							onChange={(e) => handleKillSwitchModalToggle(params.row, params.row.killSwitchActive ? true : false)}
						/>
					}
				/>
			)
		},
		{
			field: "hostName",
			sortable: true,
			headerName: "Computer Name",
			flex: 1,
			editable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={{ name: params.value }} />}
				/>
			)
		},
		{
			field: "ip",
			sortable: true,
			headerName: "Internal IP",
			flex: 1,
			editable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={{ name: params.value }} />}
				/>
			)
		},
		{
			field: "macAddress",
			sortable: true,
			headerName: "Mac Address",
			flex: 1,
			editable: false,
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridNameCell item={{ name: params.value }} />}
				/>
			)
		},
		{
			field: "lastLogUpload",
			headerName: "Last Log Upload",
			sortable: true,
			flex: 1,
			editable: false,
			valueGetter: (params) => params.value === "" ? "" : new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} />}
				/>
			)
		},
		{
			field: "lastPhoneHome",
			headerName: "Last extension heartbeat",
			sortable: true,
			flex: 1,
			editable: false,
			valueGetter: (params) => params.value === "" ? "" : new Date(params.value),
			renderCell: (params) => (
				<CoAppDataGridSkeletonCell
					cellValue={params.value}
					cellToRender={<CoAppDataGridDateTimeCell value={params.value} />}
				/>
			)
		}
	];

	const handleKillSwitchModalToggle = (workstation = null, isDeactivate = false) => {
		if (workstation) {
			setWorkstationsForDisplayInModal([workstation]);
			setWorkstationKillswitchDialogOpen(true);
			setActionType(isDeactivate ? "Deactivate" : "Activate");
		} else {
			let workstationsForDisplay = [];
			workstationsSelected.forEach((selectedUUID) => {
				let workstation = workstations.filter(workstation => {
					if (isDeactivate) {
						return selectedUUID === workstation.uuid && workstation.killSwitchActive;
					} else {
						return selectedUUID === workstation.uuid && !workstation.killSwitchActive;
					}
				});
				if (workstation.length > 0) {
					workstationsForDisplay.push(workstation[0]);
				}
			});
			setWorkstationsForDisplayInModal(workstationsForDisplay);
			setWorkstationKillswitchDialogOpen(true);
			setActionType(isDeactivate ? "Deactivate" : "Activate");
		}
	};

	const initWorkstationManagement = () => {
		axios.get(apiRoutes.getWorkstations, { headers: authHeader })
			.then((res) => {
				setWorkstations(res.data);
				setLoading(false);
			}).catch((err) => {
				console.log(err);
			});
	};

	const handleBulkWorkstationStatusToggle = () => {
		let workstationJSON = {
			newStatus: actionType === "Deactivate" ? false : true,
			workstationUUIDs: workstationsForDisplayInModal.map(workstation => workstation.uuid),
		};

		axios.put(apiRoutes.bulkWorkstationKillSwitchToggle, workstationJSON, {
			headers: authHeader
		}).then(() => {
			initWorkstationManagement();
			setWorkstationKillswitchDialogOpen(false);
			setWorkstationsSelected([]);
		})
			.catch((error) => {
				console.error(error);
				handleToastAlert(messageLevels.ERROR, messages.WORKSTATION_STATUS_TOGGLE_ERROR_MSG);
			});
	};

	const handleWorkstationStatusToggle = () => {
		axios.put(apiRoutes.workstationKillSwitch(workstationsForDisplayInModal[0].uuid), { newStatus: actionType === "Deactivate" ? false : true }, { headers: authHeader })
			.then(() => {
				initWorkstationManagement();
				setWorkstationKillswitchDialogOpen(false);
			})
			.catch((error) => {
				console.error(error);
				handleToastAlert(messageLevels.ERROR, messages.WORKSTATION_STATUS_TOGGLE_ERROR_MSG);
			});
	};

	const handleSelectionModelChange = (newSelectionModel) => {
		const fullWorkstations = workstations.filter(workstation => newSelectionModel.includes(workstation.uuid));
		const hasActiveKillSwitches = fullWorkstations.some(workstation => workstation.killSwitchActive);
		const hasInactiveKillSwitches = fullWorkstations.some(workstation => !workstation.killSwitchActive);

		if (!hasActiveKillSwitches) {
			setDeactivateIsDisabled(true);
		} else {
			setDeactivateIsDisabled(false);
		}
		if (!hasInactiveKillSwitches) {
			setActivateIsDisabled(true);
		} else {
			setActivateIsDisabled(false);
		}
		setWorkstationsSelected(newSelectionModel);
	};

	useEffect(() => {
		initWorkstationManagement();
		handleInitializeSortModel();
	}, []);

	return (
		<>
			<CoAppDatagridHeader
				title="Workstations"
				addIsPresent={false}
				editIsPresent={false}
				deleteIsPresent={false}
			/>
			<CoAppStandardDataGrid
				columns={columns}
				rows={workstations}
				pinnedColumns={["__check__", "serialNumber", "killSwitchActive"]}
				allowSelection={true}
				targetResource="workstations"
				selectionModel={workstationsSelected}
				setSelectionModel={handleSelectionModelChange}
				handleModalToggle={() => handleKillSwitchModalToggle()}
				customSelectionChangeHandler={handleSelectionModelChange}
				customSelectionRow={
					<WorkstationDataGridSelectionRow
						shouldShow={workstationsSelected.length > 0}
						selectedItems={workstationsSelected}
						workstations={workstations}
						onClearClickHandler={() => setWorkstationsSelected([])}
						bulkActivateHandler={() => handleKillSwitchModalToggle(null, false)}
						bulkDeactivateHandler={() => handleKillSwitchModalToggle(null, true)}
						activateIsDisabled={activateIsDisabled}
						deactivateIsDisabled={deactivateIsDisabled}
					/>
				}
				loading={loading}
				sortModel={sortModel}
				handleSortModelChange={handleSortModelChange}
			/>
			<CoAppKillswitchModal
				dialogOpen={workstationKillswitchDialogOpen}
				dialogTitle={workstationsSelected.length > 1 ? `${actionType} kill switch on selected workstations?` : `${actionType} workstation kill switch?`}
				dialogMessage={messages.WORKSTATION_KILLSWITCH_MSG(workstationsSelected.length, workstationsForDisplayInModal[0]?.killSwitchActive)}
				confirmClickHandler={
					workstationsForDisplayInModal.length > 1 ?
						() => handleBulkWorkstationStatusToggle()
						:
						() => handleWorkstationStatusToggle()
				}
				cancelClickHandler={() => setWorkstationKillswitchDialogOpen(false)}
				actionText={`${actionType} Kill Switch`}
				workstations={workstationsForDisplayInModal}
				isOrganizationWide={false}
			/>
		</>
	);
}