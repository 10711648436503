import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBlocker, useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, Typography, useTheme } from "@mui/material";
import axios from "axios";

import apiRoutes from "../../../../constants/api-routes";
import messageLevels from "../../../../constants/message-levels";
import messages from "../../../../constants/messages";
import pages from "../../../../constants/pages";
import useAuthHeader from "../../../../hooks/useAuthHeader";
import useCoAppNavigation from "../../../../hooks/useCoAppNavigation";
import useToastAlert from "../../../../hooks/useToastAlert";
import { selectOrganizationId, selectUser, setOrganizationIdentityProvider } from "../../../../redux/userSlice";
import CoAppDestructiveConfirmationModal from "../../../global/components/modals/coapp-destructive-confirmation-modal";
import { CoAppActionButton, CoAppCancelTextButton, CoAppLightTooltip } from "../../../global/styled/global.styled";

import ScimWizardStepper from "./stepper/scim-wizard-stepper";
import Step1 from "./steps/step-1";
import Step2 from "./steps/step-2";
import Step3 from "./steps/step-3";
import Step4 from "./steps/step-4";
import Step5 from "./steps/step-5";
import Step6 from "./steps/step-6";
import Step7 from "./steps/step-7";
import { ScimWizardButtonContainer } from "./styled/scim-wizard.styled";
import RoleValidationModal from "./role-validation-modal";
import ScimWizardGroupDetectionModal from "./scim-wizard-group-detection-modal";

function getSteps() {
	return [
		{ "num": 1, "desc": "Select IdP" },
		{ "num": 2, "desc": "Configure SSO" },
		{ "num": 3, "desc": "Enter SAML Claims" },
		{ "num": 4, "desc": "Configure SCIM" },
		{ "num": 5, "desc": "Map Roles" },
		{ "num": 6, "desc": "Map Groups (optional)" },
		{ "num": 7, "desc": "Confirm Setup" }
	];
}

export default function ScimWizard() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const theme = useTheme();
	const currentUser = useSelector(selectUser);
	const organizationId = useSelector(selectOrganizationId);
	const { handleToastAlert } = useToastAlert();
	const { openScimWizard } = useCoAppNavigation();
	const authHeader = useAuthHeader();

	const [activeStep, setActiveStep] = useState(0);
	const [stepsCompleted, setStepsCompleted] = useState({});
	const [domainError, setDomainError] = useState("");
	const [companyDomain, setCompanyDomain] = useState("");
	const [identityProviderName, setIdentityProviderName] = useState("Azure");
	const [tenantDetails, setTenantDetails] = useState({});
	const [samlClaims, setSamlClaims] = useState({ email: "", surname: "", givenName: "" });
	const [samlClaimsError, setSamlClaimsError] = useState({ email: "", surname: "", givenName: "" });
	const [metadataUrl, setMetadataUrl] = useState("");
	const [metadataError, setMetadataError] = useState("");
	const [mappedRoles, setMappedRoles] = useState({});
	const [mappedGroups, setMappedGroups] = useState({});
	const [externalGroupsDialogOpen, setExternalGroupsDialogOpen] = useState(false);
	const [externalGroupsFound, setExternalGroupsFound] = useState(false);
	const [externalGroupsTryAgain, setExternalGroupsTryAgain] = useState(false);
	const [noRolesMappedOpen, setNoRolesMappedOpen] = useState(false);
	const [superAdminNotMapped, setSuperAdminNotMapped] = useState(true);
	const [roleMappingStatus, setRoleMappingStatus] = useState({});
	const [roleValidationLoading, setRoleValidationLoading] = useState(false);

	// Blocker to prevent user from leaving the page when configuration is not complete
	let blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			companyDomain !== "" && activeStep !== 6 &&
			currentLocation.pathname !== nextLocation.pathname
	);

	const steps = getSteps();

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<>
						<Step1
							domainError={domainError}
							setDomainError={setDomainError}
							companyDomain={companyDomain}
							setCompanyDomain={setCompanyDomain}
							identityProviderName={identityProviderName}
						/>
					</>
				);
			case 1:
				return (
					<>
						<Step2
							entityId={tenantDetails.entityId}
							acsUrl={tenantDetails.acsUrl}
							identityProviderName={identityProviderName}
						/>
					</>
				);
			case 2:
				return (
					<>
						<Step3
							samlClaims={samlClaims}
							setSamlClaims={setSamlClaims}
							samlClaimsError={samlClaimsError}
							setSamlClaimsError={setSamlClaimsError}
							metadataUrl={metadataUrl}
							setMetadataUrl={setMetadataUrl}
							metadataError={metadataError}
							setMetadataError={setMetadataError}
						/>
					</>
				);
			case 3:
				return (
					<>
						<Step4
							secretToken={tenantDetails.secretScimKey}
							identityProviderName={identityProviderName}
						/>
					</>
				);
			case 4:
				return (
					<>
						<Step5
							identityProviderName={identityProviderName}
							mappedRoles={mappedRoles}
							setMappedRoles={setMappedRoles}
							setNoRolesMappedOpen={setNoRolesMappedOpen}
							setSuperAdminNotMapped={setSuperAdminNotMapped}
							validateSuperAdminRoleIsMapped={validateRoleMappings}
							roleMappingStatus={roleMappingStatus}
						/>
					</>
				);
			case 5:
				return (
					<>
						<Step6
							identityProviderName={identityProviderName}
							mappedGroups={mappedGroups}
							setMappedGroups={setMappedGroups}
						/>
					</>
				);
			case 6:
				return (
					<>
						<Step7
							groupMappings={mappedGroups}
							roleMappings={mappedRoles}
							identityProviderName={identityProviderName}
						/>
					</>
				);
			default:
				return "Unknown step";
		}
	};

	const handleSubmit = () => {
		switch (activeStep) {
			case 0:
				const domainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/;
				const validDomain = domainRegex.test(companyDomain);
				if (companyDomain === "") {
					setDomainError("Required *");
				} else if (!validDomain) {
					setDomainError("Invalid domain");
				} else {
					handleNext();
				}
				break;
			case 1:
				handleNext();
				break;
			case 2:
				let hasErrors = validateStep3();
				if (!hasErrors) {
					setSamlAppMetadata();
				}
				break;
			case 3:
				handleExternalGroupRetrieval();
				break;
			case 4:
				validateCurrentUserRoleMappings();
				break;
			case 5:
				handleNext();
				break;
			case 6:
				handleConfigurationConfirmed();
				break;
			default:
				break;
		}
	};

	const getContinueButtonText = () => {
		switch (activeStep) {
			case 2:
				return "Save SSO Configuration";
			case 3:
				return "Save SCIM Configuration";
			case 6:
				return "Finish";
			default:
				return "Next";
		}
	};

	const validateStep3 = () => {
		let hasErrors = false;
		let tempErrors = { email: "", givenName: "", surname: "" };
		if (samlClaims.email === "") {
			tempErrors.email = "Required *";
			hasErrors = true;
		}
		if (samlClaims.givenName === "") {
			tempErrors.givenName = "Required *";
			hasErrors = true;
		}
		if (samlClaims.surname === "") {
			tempErrors.surname = "Required *";
			hasErrors = true;
		}
		if (metadataUrl === "") {
			setMetadataError("Required *");
			hasErrors = true;
		}
		if (hasErrors) {
			setSamlClaimsError(tempErrors);
		}
		return hasErrors;
	};

	const validateRoleMappings = (mappings, type) => {
		if (mappings.length === 0) {
			setSuperAdminNotMapped(true);
			return;
		}
		const validateData = {
			mappings: mappings,
			type: type
		};

		axios.post(apiRoutes.validateRoleMappings, validateData, { headers: authHeader })
			.then(({ data }) => {
				if (data.status) {
					setSuperAdminNotMapped(false);
				} else {
					setSuperAdminNotMapped(true);
				}
			})
			.catch(err => {
				console.log(err);
			});
	};

	const validateCurrentUserRoleMappings = () => {
		const validateData = {
			mappings: mappedRoles,
			type: "validateCurrentUserRoleStatus",
			email: currentUser.email
		};
		setRoleValidationLoading(true);
		axios.post(apiRoutes.validateRoleMappings, validateData, { headers: authHeader })
			.then(({ data }) => {
				if (data.superAdminStatus) {
					handleNext();
					setRoleValidationLoading(false);
				} else {
					setNoRolesMappedOpen(true);
					setRoleMappingStatus(data);
					setRoleValidationLoading(false);
				}
			})
			.catch(err => {
				console.log(err);
				setRoleValidationLoading(false);
			});
	};

	const handleCloseRoleValidationModal = () => {
		setNoRolesMappedOpen(false);
		setRoleMappingStatus({});
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleExternalGroupRetrieval = () => {
		axios.get(apiRoutes.getExternalGroups(organizationId), { headers: authHeader })
			.then((res) => {
				if (res.data.length > 0 && !externalGroupsDialogOpen) {
					handleNext();
				} else if (externalGroupsDialogOpen && res.data.length > 0) {
					setExternalGroupsFound(true);
				} else {
					setExternalGroupsDialogOpen(true);
					setTimeout(() => {
						setExternalGroupsTryAgain(false);
					}, 4000);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleClearCurrentConfiguration = () => {
		axios.delete(apiRoutes.ssoAndScim(currentUser.organizationId), { headers: authHeader })
			.then(() => {
			})
			.catch(err => console.error(err));
	};

	const handleConfigurationConfirmed = () => {
		const groupsToMap = Object.entries(mappedGroups).filter(([key, value]) => value.length > 0);
		const rolesToMap = Object.entries(mappedRoles).filter(([key, value]) => value.length > 0);
		const unmappedGroups = Object.entries(mappedGroups).filter(([key, value]) => value.length === 0);
		const unmappedRoles = Object.entries(mappedRoles).filter(([key, value]) => value.length === 0);
		const postData = {
			mappedGroups: groupsToMap,
			mappedRoles: rolesToMap,
			unmappedGroups: unmappedGroups,
			unmappedRoles: unmappedRoles,
			identityProviderName: identityProviderName
		};
		axios.post(apiRoutes.ssoAndScim(currentUser.organizationId), postData, { headers: authHeader })
			.then(() => {
				handleToastAlert(messageLevels.SUCCESS, messages.SSO_AND_SCIM_CONFIGURED_SUCCESS_TOAST_MSG);
				navigate(pages.organizationManagement);
				dispatch(setOrganizationIdentityProvider(identityProviderName));
			})
			.catch((err) => {
				handleToastAlert(messageLevels.ERROR, messages.SSO_AND_SCIM_CONFIGURED_ERROR_TOAST_MSG);
				console.log(err);
			});
	};

	/**
	* Step Progression
	*/
	const handleMoveUpOneStep = () => {
		setActiveStep((activeStep) => activeStep + 1);
	};

	const handleNext = async () => {
		switch (activeStep) {
			case 0:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			case 1:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			case 2:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			case 3:
				setExternalGroupsDialogOpen(false);
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			case 4:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				setNoRolesMappedOpen(false);
				break;
			case 5:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			case 6:
				setStepsCompleted({ ...stepsCompleted, [`${activeStep}`]: true });
				handleMoveUpOneStep();
				break;
			default:
				console.log("unknown case");
				break;
		}
	};

	const setSamlAppMetadata = () => {
		const metadata = {
			tenantId: tenantDetails.tenantId,
			metadataUrl: metadataUrl,
			emailClaimName: samlClaims.email,
			givenNameClaimName: samlClaims.givenName,
			surnameClaimName: samlClaims.surname,
			domain: companyDomain
		};
		axios.post(apiRoutes.setSamlAppMetadata(currentUser.organizationId), metadata, { headers: authHeader })
			.then(() => {
				handleNext();
				handleToastAlert(messageLevels.SUCCESS, messages.SSO_CONFIGURED_SUCCESS_TOAST_MSG);
			})
			.catch(err => {
				handleToastAlert(messageLevels.ERROR, messages.SSO_CONFIGURED_ERROR_TOAST_MSG);
				console.log(err);
			});
	};

	const initOrganization = () => {
		axios.get(apiRoutes.getOrganizationWithSamlMetadata(currentUser.organizationId), {
			headers: authHeader
		})
			.then((res) => {
				setTenantDetails({ secretScimKey: res.data.secretScimKey, tenantId: res.data.descopeTenantId, entityId: res.data.tenantEntityId, acsUrl: res.data.tenantAcsUrl });
			})
			.catch(err => console.error(err));
	};

	/**
	 * Stops user when refreshing the page. And prompts user with browser alert.
	 * Note: Fired before the content is unloaded.
	 */
	const alertUser = (e) => {
		e.preventDefault();
	};

	/**
	 * Deletes the current configuration when the user chooses to refresh the page.
	 * Note: Fired after the content is unloaded or when a user chooses to leave the page.
	 */
	const deleteConfiguration = () => {
		handleClearCurrentConfiguration();
		blocker.proceed();
	};

	const handleCancelExit = () => {
		openScimWizard(false);
		blocker.reset();
	};

	useEffect(() => {
		initOrganization();
		window.addEventListener("beforeunload", alertUser);
		window.addEventListener("unload", deleteConfiguration);
		return () => {
			window.removeEventListener("beforeunload", alertUser);
			window.removeEventListener("unload", deleteConfiguration);
		};
	}, []);

	return (
		<Fragment>
			<ScimWizardStepper
				activeStep={activeStep} setActiveStep={setActiveStep} stepsCompleted={stepsCompleted}
				handleSubmit={handleSubmit} handleBack={handleBack} steps={steps}
			/>
			{getStepContent(activeStep)}
			<CoAppDestructiveConfirmationModal
				dialogOpen={blocker.state === "blocked"}
				dialogTitle="Leave SSO + SCIM Configuration?"
				dialogMessage="You have not completed SSO + SCIM configuration. All progress will be lost."
				confirmClickHandler={deleteConfiguration}
				cancelClickHandler={handleCancelExit}
				actionText="Confirm"
			/>
			<ScimWizardGroupDetectionModal
				dialogOpen={externalGroupsDialogOpen}
				closeClickHandler={() => setExternalGroupsDialogOpen(false)}
				confirmClickHandler={handleNext}
				tryAgainClickHandler={handleExternalGroupRetrieval}
				identityProviderName={identityProviderName}
				groupsDetected={externalGroupsFound}
				tryAgain={externalGroupsTryAgain}
				setTryAgain={setExternalGroupsTryAgain}
			/>
			<RoleValidationModal
				dialogOpen={noRolesMappedOpen}
				loading={roleValidationLoading}
				roleItems={[]}
				confirmClickHandler={handleNext}
				cancelClickHandler={handleCloseRoleValidationModal}
				roleMappingStatus={roleMappingStatus}
				identityProviderName={identityProviderName}
			/>
			<Dialog open={roleValidationLoading} PaperProps={{ sx: { borderRadius: "10px" } }}>
				<DialogTitle>
					<Typography variant="body1" sx={{ fontWeight: 600, marginLeft: "10px" }}>Validating your configured role mappings...</Typography>
				</DialogTitle>
			</Dialog>
			<ScimWizardButtonContainer>
				<CoAppCancelTextButton onClick={handleBack} disabled={activeStep === 0}>Back</CoAppCancelTextButton>
				{
					activeStep === 4 && superAdminNotMapped ?
						<CoAppLightTooltip title={messages.SCIM_ROLE_WARNING_TOOLTIP}>
							<span>
								<CoAppActionButton
									onClick={handleSubmit}
									disabled={true}
									sx={{
										"&.Mui-disabled": {
											backgroundColor: theme.palette.action.disabledBackground,
											color: theme.palette.action.disabled
										}
									}}
								>{getContinueButtonText()}</CoAppActionButton>
							</span>
						</CoAppLightTooltip>
						:
						<CoAppActionButton onClick={handleSubmit}>{getContinueButtonText()}</CoAppActionButton>

				}
			</ScimWizardButtonContainer>
		</Fragment>
	);
}
