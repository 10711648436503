import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";

import { selectName } from "../../redux/ruleWizardSlice";

import WizardStepper from "./stepper/wizard-stepper";

export default function RuleWizardHeader(props) {
	const name = useSelector(selectName);

	if (props.activeStep === 0) {
		return (
			<Stack direction="row" spacing={2} sx={{ alignItems: "flex-start"}}>
				<WizardStepper activeStep={props.activeStep} setActiveStep={props.setActiveStep} stepsCompleted={props.stepsCompleted} handleStepButtonClick={props.handleStepButtonClick}
					handleSubmit={props.handleSubmit} handleBack={props.handleBack} handleExitEditing={props.handleExitEditing} isSavingProgress={props.isSavingProgress}
					editedVersionOfRule={props.editedVersionOfRule} wizardFormError={props.wizardFormError} setWizardFormError={props.setWizardFormError}
					steps={props.steps} />
			</Stack>
		);
	} else {
		return (
			<>
				<Stack direction="row" spacing={4}>
					<Stack direction="column" spacing={1} sx={{ marginTop: "18px!important"}}>
						<Typography variant="h6" sx={{ fontWeight: "600"}}>{name} </Typography>
					</Stack>
					<WizardStepper activeStep={props.activeStep} setActiveStep={props.setActiveStep} stepsCompleted={props.stepsCompleted} handleStepButtonClick={props.handleStepButtonClick}
						handleSubmit={props.handleSubmit} handleBack={props.handleBack} handleExitEditing={props.handleExitEditing} isSavingProgress={props.isSavingProgress}
						editedVersionOfRule={props.editedVersionOfRule} wizardFormError={props.wizardFormError} setWizardFormError={props.setWizardFormError}
						steps={props.steps} />
				</Stack>
			</>
		);
	}
}