import { Grid, Stack, StepConnector, stepConnectorClasses, styled, Typography } from "@mui/material";

export const WizardContainer = styled("div")(({ theme }) => ({
	padding: "5px 0 0 50px",
	height: "100%",
	position: "relative",
	width:"100%",

	"& .RuleWizardHeader-desc-tags": {
		maxWidth: "400px",
		[theme.breakpoints.down("1700")]: {
			display: "none"
		}
	}
}));

export const WizardStepContainer = styled("div")(({ theme }) => ({
	height: "100%",
	marginTop: "100px",
	width: "100%",

	[theme.breakpoints.down("1700")]: {
		marginTop: "60px"
	}
}));

export const WizardHeaderTag = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#E3E6EC" : "#E3E6EC",
	...theme.typography.body2,
	padding: theme.spacing(0.2),
	textAlign: "left",
	fontSize: "0.70rem",
	fontWeight: "700",
	color: "#7482A0",
	float: "left",
	borderRadius: "5px",
	display: "flex"
}));

export const RuleImage = styled("img")(({ theme }) => ({
	backgroundColor: "#FFFFFF",
	border: "solid 1px #DCDCDC",
	borderRadius: theme.spacing(0.75),
	boxShadow: "0 1px 3px grey",
	maxWidth: "600px",
	padding: "25px"
}));

export const WizardStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
	display: "flex",
	height: 40,
	padding: "10px",
	alignItems: "center",
	borderRadius: "50%",
	zIndex: 2,
	"& .WizardStepIcon-completedIcon": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: ownerState.active ? "#FFFFFF" : `${theme.palette.info.main}`,
		border: `12px solid ${theme.palette.info.main}`
	},
	"& .WizardStepIcon-circle": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "#FFFFFF",
		border: `10px solid ${theme.palette.info.main}`
	},
	"& .WizardStepIcon-circle-active-incomplete": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "#FFFFFF",
		border: `11px solid ${theme.palette.info.main}`,
		opacity: "0.5"
	},
	"& .WizardStepIcon-circle-inactive-incomplete": {
		width: 5,
		height: 5,
		borderRadius: "50%",
		backgroundColor: "grey",
		border: "10px solid #eaeaf0"
	}
}));

export const WizardStepConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 28
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: `${theme.palette.info.main}`
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: `${theme.palette.info.main}`
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderTopWidth: 4,
		marginLeft: "-7.5px",
		marginRight: "-10px"
	},
}));

export const WizardStepperContainer = styled(Stack)(({ theme }) => ({
	alignItems: "flex-start",
	justifyContent: "right",
	position: "absolute",
	right: 25,
	width: "100%",

	"& .MuiStepLabel-label": {
		[theme.breakpoints.down("1700")]: {
			display: "none"
		},
	},
	"& .StepButton": {
		marginTop: "13px",
	}
}));

/**
 * Step 4
 */
export const WizardOperatorContainer = styled("div")(({ theme }) => ({
	border: "1px solid #A9A9A9",
	padding: "5px",
}));

export const WizardOperatorItem = styled(Grid)((props) => ({
	alignContent: "center",
	border: "2px solid #DB7A74",
	borderRadius: "5px",
	color: `${props.selected ? "#2FBC70" : "black"}`,
	display: "flex",
	flexDirection: "column",
	fontSize: "0.75rem",
	justifyContent: "center",
	padding: "2px",
	textAlign: "center",
	"&:hover": {
		backgroundColor: "#F8F9FA",
		cursor: "move", /* fallback if grab cursor is unsupported */
		cursor: "grab",
		cursor: "-moz-grab",
		cursor: "-webkit-grab"
	},
	"&:active": {
		cursor: "grabbing",
		cursor: "-moz-grabbing",
		cursor: "-webkit-grabbing"
	}
}));

export const WizardOperatorGrid = styled(Grid)(({ theme }) => ({
	display: "grid",
	gridAutoRows: "auto",
	gridGap: "0.2rem",
	padding: "5px",
	borderRadius: "5px",
	width: "100%",
	[theme.breakpoints.up("1000")]: {
		gridTemplateColumns: "repeat(2, 1fr)",
		height: "250px",
		overflowY: "scroll"
	},
	[theme.breakpoints.up("1800")]: {
		gridTemplateColumns: "repeat(4, 1fr)",
		height: "250px",
	}
}));

export const FormulaHelpText = styled(Typography)(({ theme }) => ({
	fontStyle: "italic",
}));

/**
 * Step 5
 */
export const RuleSummary = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(2),
	textAlign: "left",
	color: theme.palette.text.secondary,
}));

export const RuleFormulaSummary = styled("div")(({ theme }) => ({
	backgroundColor: "#F8F9FA",
	...theme.typography.body2,
	color: "#111111",
	display: "flex",
	flexWrap: "wrap",
	justifyContent: "flex-start",
	margin: "5px",
	padding: "15px",
}));

export const RuleFormulaSummaryItem = styled(Typography)(() => ({
	padding: "5px",
	fontWeight: "400"
}));