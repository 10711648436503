import { Stack } from "@mui/material";

import {
	ScimWizardCheckIcon, ScimWizardConfirmationListHeader, ScimWizardResourceList,
	ScimWizardResourceListHeader, ScimWizardResourceListItem, ScimWizardResourceListTitle,
	ScimWizardResourceNameTypography
} from "./styled/scim-wizard.styled";

export default function ScimWizardConfirmList(props) {
	const { mapped, unmapped, resource } = props;
	return (
		<div>
			<ScimWizardConfirmationListHeader variant="h6">CoApp {resource}</ScimWizardConfirmationListHeader>
			<Stack direction="row" spacing={3}>
				<ScimWizardResourceList ismapped="true">
					<ScimWizardResourceListHeader ismapped="true">
						<Stack direction="row" justifyContent="space-between">
							<ScimWizardResourceListTitle variant="body1">Mapped</ScimWizardResourceListTitle>
							<ScimWizardCheckIcon />
						</Stack>
					</ScimWizardResourceListHeader>
					{
						mapped.map((item) => (
							<ScimWizardResourceListItem key={item}>
								<ScimWizardResourceNameTypography title={item} key={item} variant="body2" noWrap={true}>{item}</ScimWizardResourceNameTypography>
							</ScimWizardResourceListItem>
						))
					}
				</ScimWizardResourceList>
				<ScimWizardResourceList>
					<ScimWizardResourceListHeader>
						<ScimWizardResourceListTitle variant="body1">Unmapped</ScimWizardResourceListTitle>
					</ScimWizardResourceListHeader>
					{
						unmapped.map((item) => (
							<ScimWizardResourceListItem key={item}>
								<ScimWizardResourceNameTypography title={item} key={item} variant="body2" noWrap={true}>{item}</ScimWizardResourceNameTypography>
							</ScimWizardResourceListItem>
						))
					}
				</ScimWizardResourceList>
			</Stack>
		</div>
	);
}