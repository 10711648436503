import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useDescope, useSession } from "@descope/react-sdk";

import Unauthorized from "./components/global/components/unauthorized";
import pages from "./constants/pages";
import useAuthorization from "./hooks/useAuthorization";
import { resetNavigationState } from "./redux/navigationSlice";
import {
	setEmail, setFirstName, setGroups, setLastName, setOrgId,
	setPermissions, setPhone, setRoles, setUserId
} from "./redux/userSlice";

const ProtectedRoute = ({ permissions, isInclusive }) => {
	const isAuthorized = useAuthorization(permissions, isInclusive);

	let dispatch = useDispatch();
	let navigate = useNavigate();

	const { logout } = useDescope();

	const { isAuthenticated, isSessionLoading } = useSession();

	const logoutUser = async () => {
		try {
			logout();
		} catch (e) {
			console.error(e);
		}

		dispatch(setUserId(null));
		dispatch(setEmail(null));
		dispatch(setFirstName(null));
		dispatch(setGroups([]));
		dispatch(setLastName(null));
		dispatch(setPermissions([]));
		dispatch(setPhone(null));
		dispatch(setRoles([]));
		dispatch(setOrgId(null));
		dispatch(resetNavigationState());
		navigate(pages.login);
	};

	useEffect(() => {
		if (!isSessionLoading) {
			if (!isAuthenticated) {
				logoutUser();
			}
		}
	}, [isSessionLoading, isAuthenticated]);

	if (isSessionLoading) {
		return (
			<></>
		);
	} else if (isAuthenticated && !isAuthorized) {
		return (
			<Unauthorized />
		);
	} else if (isAuthenticated && isAuthorized) {
		return (
			<div>
				<Outlet />
			</div>
		);
	}
};

export default ProtectedRoute;